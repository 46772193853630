import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Space } from 'antd';
import i18n from 'i18next';
import dayjs from 'dayjs';
import UserDropdown from './components/UserDropdown';
import Notifications from 'sections/Notifications';
import LanguageSelector from 'components/LanguageSelector';
import { signOut, switchLanguage } from 'sections/Login/reducer';
import { resetAllDataAction } from 'store/nextStore';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import { LANGUAGES } from './constants';
import { STopBar } from './styled-components';

import crystalcheck from 'images/crystal-check.svg';

const id_generator = new UniqIdsGenerator('topbar');

interface TopBarProps {
  userAttributes: {
    [key: string]: any;
  };
  isSignedIn: boolean;
  dashboardLogoutUrl?: string;
  signOut: (params: any) => void;
  switchLanguage: (lang: string) => void;
  resetAllData: () => void;
}

const TopBar: FC<TopBarProps> = ({
  userAttributes,
  isSignedIn,
  dashboardLogoutUrl,
  signOut,
  switchLanguage,
  resetAllData,
}) => {
  const {
    profiles_count,
    business,
    lang: currentLanguage,
    first_name,
    last_name,
    langs,
  } = userAttributes;
  const [isLogo, setIsLogo] = useState<boolean>(true);
  let navigate = useNavigate();

  const isMblUser = profiles_count > 1;
  const businessPrettyName = business?.pretty_name;

  useEffect(() => {
    const logoUrl = business?.logo_url;
    if (isSignedIn && logoUrl) {
      const image = new Image();
      image.src = business.logo_url;
      image.onload = () => setIsLogo(true);
      image.onerror = () => setIsLogo(false);
    }
  }, [isSignedIn, business?.logo_url]);

  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage); // Sync i18next with Redux language state
    }
  }, [currentLanguage]);

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      const params = {
        'access-token': sessionStorage.getItem('access-token'),
        client: sessionStorage.client,
        uid: sessionStorage.uid,
      };

      await signOut(params);
    } catch (e) {
      console.error('Logout failed:', e);
    } finally {
      resetAllData();

      if (dashboardLogoutUrl) {
        const logoutIframe = document.createElement('iframe');
        logoutIframe.style.display = 'none';
        logoutIframe.src = dashboardLogoutUrl;
        logoutIframe.setAttribute('id', 'logoutIframe');
        logoutIframe.onload = () => {
          logoutIframe.parentNode.removeChild(logoutIframe);
        };
        document.body.appendChild(logoutIframe);
      }

      navigate('/sign_in');
    }
  };

  const handleLogo = (): ReactNode => {
    const { name, logo_url } = business || {};

    return isSignedIn && logo_url && isLogo ? (
      <img src={logo_url} className="logo-icon" alt="Business Logo" />
    ) : (
      <h1 className="company-name">{name?.toUpperCase()}</h1>
    );
  };

  const logoContent = isSignedIn ? handleLogo() : null;

  const getLanguagesList = useCallback(() => {
    return (langs || []).map((lang) => ({
      value: lang,
      ...LANGUAGES[lang],
    }));
  }, [langs]);

  const handleLanguageChange = (selectedLang: string) => {
    switchLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    dayjs.locale(selectedLang);
  };

  const languagesList = getLanguagesList();

  return (
    <STopBar>
      <div className="topbar-wrapper" id={id_generator.get_id('wrapper')}>
        <div className="crystalcheck">
          <div>
            <img
              src={crystalcheck}
              className="crystalcheck-icon"
              alt="Crystalchain"
            />
          </div>
        </div>
        {isSignedIn && (
          <>
            <div className="logo-wrapper" id={id_generator.get_id('wrapper')}>
              {logoContent}
            </div>
            <div className="right-box-wrapper">
              <div
                className="profile-wrapper"
                id={id_generator.get_id('user-wrapper')}
              >
                <Space size="middle">
                  <Notifications />
                  <UserDropdown
                    firstName={first_name}
                    lastName={last_name}
                    isMblUser={isMblUser}
                    businessPrettyName={businessPrettyName}
                    handleLogout={handleLogout}
                  />
                  {Array.isArray(languagesList) && languagesList.length > 0 && (
                    <LanguageSelector
                      language={currentLanguage}
                      languageList={languagesList}
                      handleLanguageChange={handleLanguageChange}
                    />
                  )}
                </Space>
              </div>
            </div>
          </>
        )}
      </div>
    </STopBar>
  );
};

const mapDispatchToProps = {
  resetAllData: resetAllDataAction,
  signOut,
  switchLanguage,
};

const mapStateToProps = ({ auth: { currentUser }, commonIframe }: any) => ({
  userAttributes: currentUser?.attributes,
  isSignedIn: currentUser?.isSignedIn,
  dashboardLogoutUrl: commonIframe?.dashboardLogoutUrl,
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
