import styled from 'styled-components';
import { Button, Space } from 'antd';
import { colors, font, transition } from 'App/styleVariables';

export const SDropdownContainer = styled.div`
  .ant-dropdown-menu-item {
    cursor: initial !important;

    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const SDropdownTrigger = styled(Space)`
  cursor: pointer;
  
  .anticon-down {
    font-size: 12px;
    color: ${({ theme }) => theme.header.user?.name?.color || '#ffffff'};
    font-family: ${({ theme }) => theme.header.user?.name?.family || font};
  }

  .user-name {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.header.user?.name?.color || '#ffffff'};
    font-family: ${({ theme }) => theme.header.user?.name?.family || font};
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user-business {
    margin: 4px 0 0;
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.header.user?.name?.color || '#ffffff'};
    font-family: ${({ theme }) => theme.header.user?.name?.family || font};
    max-width: 190px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const SDropdownContent = styled.div`
  padding: 5px 0;

  .items-divider {
    margin: 10px 0;
  }
`;

export const SButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 1;
  border-radius: 8px;
  color: ${({ theme }) =>
    theme.header.user?.button?.passiveColor || colors.gray};
  font-family: ${({ theme }) => theme.header.user?.button?.family || font};
  cursor: pointer;
  transition: ${transition};

  .button-industry-icon {
    margin-right: 4px;
    width: 14px;
    height: 14px;

    path {
      fill: ${({ theme }) =>
        theme.header.user?.button?.passiveColor || colors.gray};
    }
  }

  &:hover {
    color: ${({ theme }) =>
      theme.header.user?.button?.activeColor || colors.primary} !important;
    background-color: ${({ theme }) =>
      theme.header.user?.button?.activeBgColor ||
      colors.secondary10} !important;

    .button-industry-icon {
      path {
        fill: ${({ theme }) =>
          theme.header.user?.button?.activeColor || colors.primary} !important;
      }
    }
  }
`;
