import styled from 'styled-components';
import { FloatButton, Menu } from 'antd';
import { colors, transition } from 'App/styleVariables';

const SUPPORT_BTN_SIZE = '53px';

const StyledMenuWrapper = styled.div`
  height: 100%;
  min-height: ${(props) => {
    return props.businessname?.includes('sb-') ? '1015px' : '891px';
  }};
  background: ${(props) => props.theme.sidebar.background} !important;
  width: ${(props) => {
    const current_width = props.theme.sidebar.width
      ? props.theme.sidebar.width
      : '259px';
    return props.$inlineCollapsed
      ? '82px !important'
      : `${current_width} !important`;
  }};

  .menu-button {
    background: ${(props) => props.theme.sidebar.background} !important;
    width: 100%;
    padding: 0 !important;
    border: none;
    box-shadow: none;

    .anticon {
      font-size: 20px;
      margin-right: ${(props) =>
        props.$inlineCollapsed ? '0' : '12px !important'};
    }
    .button-label {
      display: ${(props) => (props.$inlineCollapsed ? 'none' : 'block')};
    }

    &:hover {
      color: ${(props) => props.theme.sidebar.hoverItem} !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  padding-top: 30px !important;
  width: ${(props) => {
    const current_width = props.theme.sidebar.width
      ? props.theme.sidebar.width
      : '259px';
    return props.inlineCollapsed
      ? '82px !important'
      : `${current_width}!important`;
  }};
  border-right: ${(props) =>
    props.theme.sidebar.borderRight || '1px solid #f0f0f0'};
  border-inline-end: none !important;
  background: ${(props) => props.theme.sidebar.background} !important;

  &.ant-menu-inline-collapsed {
    > .ant-menu-item,
    > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 32px !important;
    }

    .anticon + span {
      max-width: 0;
    }
  }

  .ant-menu-item {
    width: 100%;
    margin: 0 !important;
  }

  .ant-menu-title-content,
  .menu-button.menu-item {
    font-size: ${(props) => props.theme.sidebar.itemMenu.size} !important;
    font-family: ${(props) => props.theme.sidebar.itemMenu.family} !important;
    font-weight: ${(props) => props.theme.sidebar.itemMenu.weight} !important;
    color: ${(props) => props.theme.sidebar.itemMenu.color} !important;
    line-height: 1.5;
  }

  & > .ant-menu-item {
    height: 60px !important;

    &:hover {
      .ant-menu-title-content,
      .menu-button.menu-item {
        color: ${(props) => props.theme.sidebar.hoverItem} !important;
      }
    }
  }

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      margin: 0 !important;
      height: 60px !important;
      width: ${(props) =>
        props.inlineCollapsed ? '100%' : 'calc(100% - 8px)'};
      &:hover {
        color: ${(props) => props.theme.sidebar.hoverItem} !important;
        background-color: transparent !important;

        .ant-menu-title-content {
          color: ${(props) => props.theme.sidebar.hoverItem} !important;
        }
        .item-icon svg {
          fill: ${(props) => props.theme.sidebar.hoverItem} !important;
        }
      }
    }

    .ant-menu.ant-menu-sub {
      background-color: transparent;
    }
    .ant-menu-item {
      display: flex !important;
      align-items: center !important;
      margin-top: 4px !important;
      margin-bottom: 12px !important;
      height: 19px !important;
      background: ${(props) => props.theme.sidebar.background} !important;
      &:last-child {
        margin-bottom: 0 !important;
      }

      .ant-menu-title-content,
      .menu-button.sub-menu-item {
        display: flex !important;
        align-items: center !important;
        padding-left: 20px;
        font-size: ${(props) => props.theme.sidebar.subMenu.size} !important;
        font-family: ${(props) =>
          props.theme.sidebar.subMenu.family} !important;
        font-weight: ${(props) =>
          props.theme.sidebar.subMenu.weight} !important;
        color: ${(props) => props.theme.sidebar.itemMenu.color} !important;
        background: ${(props) => props.theme.sidebar.background} !important;
      }

      &:hover {
        .ant-menu-title-content,
        .menu-button.sub-menu-item {
          color: ${(props) => props.theme.sidebar.hoverItem} !important;
        }
      }
    }
  }

  .ant-menu-submenu-arrow {
    &::before {
      width: ${(props) => props.theme.sidebar.arrow.size} !important;
      background: ${(props) => props.theme.sidebar.arrow.background} !important;
    }
    &::after {
      width: ${(props) => props.theme.sidebar.arrow.size} !important;
      background: ${(props) => props.theme.sidebar.arrow.background} !important;
    }
  }

  .item-icon {
    margin-right: ${(props) => (props.collapsed ? '24px' : '4px !important')};
    font-size: ${(props) => props.theme.sidebar.icon.size} !important;
    font-family: ${(props) => props.theme.sidebar.icon.family} !important;
    font-weight: ${(props) => props.theme.sidebar.icon.weight} !important;
  }

  .ant-menu-submenu:not(.ant-menu-item-selected),
  .ant-menu-item:not(.ant-menu-item-selected) {
    .item-icon svg {
      fill: ${(props) => props.theme.sidebar.itemMenu.color} !important;
    }

    &:hover {
      background-color: transparent !important;

      .item-icon svg {
        fill: ${(props) => props.theme.sidebar.hoverItem} !important;
      }
    }
  }

  & .ant-menu-item-selected,
  .ant-menu.ant-menu-sub .ant-menu-item-selected {
    color: ${(props) => props.theme.sidebar.activeItem} !important;
    background-color: transparent;
    .ant-menu-title-content {
      color: ${(props) => props.theme.sidebar.activeItem} !important;
    }

    &:hover {
      .ant-menu-title-content {
        color: ${(props) => props.theme.sidebar.hoverItem} !important;
      }
      .item-icon svg {
        fill: ${(props) => props.theme.sidebar.hoverItem} !important;
      }
    }
  }
  .ant-menu-submenu.ant-menu-submenu-selected {
    color: ${(props) =>
      `${props.theme.sidebar.activeItem} !important` || colors.secondary};
    .item-icon svg {
      fill: ${(props) => props.theme.sidebar.activeItem} !important;
    }
  }
`;

const SSupportButton = styled(FloatButton)`
  height: ${SUPPORT_BTN_SIZE};
  width: ${SUPPORT_BTN_SIZE};
  z-index: 2;
  box-shadow: 0 5px 12px 3px rgba(0, 0, 0, 0.15);

  .ant-float-btn-body {
    width: ${SUPPORT_BTN_SIZE};
    height: ${SUPPORT_BTN_SIZE};
    background-color: ${({ theme }) =>
      theme.support?.passiveBgColor || '#ffffff'};
    transition: ${transition};

    .ant-float-btn-content {
      min-height: 53px;
      padding: 0;

      .ant-float-btn-icon {
        width: 53px;
        font-size: 28px;
        color: ${({ theme }) =>
          theme.support?.passiveColor || colors.primary};
        transition: ${transition};
      }
    }

    &:hover {
      background-color: ${({ theme }) =>
        theme.support?.activeBgColor || '#ffffff'};

      .ant-float-btn-content {
        .ant-float-btn-icon {
          color: ${({ theme }) =>
            theme.support?.activeColor || colors.secondary};
        }
      }
    }
  }
`;

export { StyledMenuWrapper, StyledMenu, SSupportButton };
