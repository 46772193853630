import React, { FC } from 'react';
import { Divider, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ChangeBusinessBtn from '../ChangeBusinessBtn';
import { Avatar } from 'components/library';
import UniqIdsGenerator from 'lib/uniqIdsGenerator';
import {
  SButton,
  SDropdownContainer,
  SDropdownContent,
  SDropdownTrigger,
} from './styled-components';

const id_generator = new UniqIdsGenerator('top-user-dropdown');

interface UserDropdownProps {
  firstName: string;
  lastName: string;
  isMblUser: boolean;
  businessPrettyName?: string;
  handleLogout: (e: any) => Promise<void>;
}

const UserDropdown: FC<UserDropdownProps> = ({
  firstName,
  lastName,
  isMblUser,
  businessPrettyName,
  handleLogout,
}) => {
  const { t } = useTranslation();
  const loggedUser = `${firstName} ${lastName}`;

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <SDropdownContent>
          {isMblUser && (
            <div className="user-dropdown-item">
              <ChangeBusinessBtn
                id={id_generator.get_id('change-business-btn')}
              />
              <Divider className="items-divider" />
            </div>
          )}
          <div className="user-dropdown-item">
            <SButton
              id={id_generator.get_id('btn-logout')}
              type="text"
              className="logout-btn inside"
              onClick={handleLogout}
            >
              <LogoutOutlined />
              {t('header.logout')}
            </SButton>
          </div>
        </SDropdownContent>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      dropdownRender={(menu) => (
        <SDropdownContainer>
          {React.cloneElement(menu as React.ReactElement)}
        </SDropdownContainer>
      )}
      overlayClassName="header-user-dropdown"
    >
      <SDropdownTrigger>
        <Avatar firstName={firstName} lastName={lastName} context={'header'} />
        <div className="header-user-info">
          <p className="user-name">{loggedUser}</p>
          {isMblUser && businessPrettyName && (
            <p className="user-business">{businessPrettyName}</p>
          )}
        </div>
        <DownOutlined />
      </SDropdownTrigger>
    </Dropdown>
  );
};

export default UserDropdown;
